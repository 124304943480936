<template>
	<Dialog
		v-if="moreDataDialog"
		:dialog="moreDataDialog"
		:dialog-width="dialogWidth"
		@close="closeDialog"
	>
		<template v-slot:title>
			All<template v-if="dialogTitle"> {{ dialogTitle }}s</template>
			<v-spacer></v-spacer>
			<span v-if="barcode">
				<v-chip color="blue darken-4" label>
					<span class="white--text fw-600">
						{{ barcode }}
					</span>
				</v-chip>
			</span>
		</template>
		<template v-slot:body>
			<div class="tab-layout-content px-4" style="max-height: calc(100vh - 350px); overflow-y: scroll">
				<template v-if="allData && allData.length > 0">
					<div class="my-2" v-for="(rec, index) in allData" :key="index">
						<div class="d-flex align-center">
							<v-avatar left small>
								<img v-if="rec.profile_img" :src="rec.profile_img" />
								<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
							</v-avatar>
							<div class="text-capitalize ml-5 fw-600">
								<p><v-icon size="21">mdi-barcode</v-icon> {{ rec.barcode }}</p>
								<p>
									<v-icon size="18" style="margin-bottom: 2px">mdi-account-circle</v-icon>
									{{ rec.display_name || rec.first_name }}
								</p>
								<p class="text-lowercase">
									<v-icon size="18" style="margin-bottom: 2px">mdi-email</v-icon> {{ rec.email }}
								</p>
								<p>
									<v-icon size="18" style="margin-bottom: 2px">mdi-phone</v-icon> {{ rec.phone_number }}
								</p>
							</div>
						</div>
						<v-divider v-if="index != allData.length - 1"></v-divider>
					</div>
				</template>
				<template v-else>
					<p class="m-0 row-not-found text-center">
						<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
						Uhh... There are no {{ dialogTitle }} at the moment.
					</p>
				</template>
			</div>
		</template>
		<template v-slot:action>
			<v-btn class="close-btn" depressed tile @click="closeDialog"> Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import { cloneDeep } from "lodash";

export default {
	name: "more-details",
	props: {
		moreDataDialog: {
			type: Boolean,
			default: false,
		},
		dialogWidth: {
			type: [Number, String],
			default: 600,
		},
		dialogTitle: {
			type: String,
			default: null,
		},
		moreData: {
			type: [Array, Object],
			default: () => [],
		},
		barcode: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			allData: [],
		};
	},
	watch: {
		moreData: {
			deep: true,
			immediate: true,
			handler(param) {
				let _data = cloneDeep(param);
				this.allData = [..._data];
			},
		},
	},
	components: { Dialog },
	methods: {
		closeDialog() {
			this.$emit("close");
		},
	},
};
</script>
